var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.type == 3 ? _c('div', [_c('el-upload', {
    staticClass: "picture-uploader",
    class: [_vm.size],
    attrs: {
      "action": "",
      "accept": _vm.accept,
      "list-type": "picture-card",
      "multiple": "",
      "auto-upload": false,
      "file-list": _vm.fileList,
      "on-remove": _vm.handleRemove,
      "on-change": _vm.imgChangeNUpload
    },
    scopedSlots: _vm._u([{
      key: "file",
      fn: function fn(_ref) {
        var file = _ref.file;
        return _c('div', {
          staticClass: "default-upload-file"
        }, [_c('img', {
          staticClass: "el-upload-list__item-thumbnail",
          staticStyle: {
            "object-fit": "cover"
          },
          attrs: {
            "src": file.url,
            "alt": ""
          }
        }), _c('span', {
          staticClass: "el-upload-list__item-actions"
        }, [_c('span', {
          staticClass: "el-upload-list__item-preview",
          on: {
            "click": function click($event) {
              return _vm.handlePreview(file);
            }
          }
        }, [_c('i', {
          staticClass: "el-icon-zoom-in"
        })]), _c('span', {
          staticClass: "el-upload-list__item-delete",
          on: {
            "click": function click($event) {
              return _vm.handleRemove(file);
            }
          }
        }, [_c('i', {
          staticClass: "el-icon-delete"
        })])])]);
      }
    }], null, false, 3576037133)
  }, [_c('div', {
    staticClass: "default-upload-btn",
    attrs: {
      "slot": "default"
    },
    slot: "default"
  }, [_c('i', {
    staticClass: "el-icon-plus"
  }), _vm.title ? _c('span', [_vm._v(_vm._s(_vm.title))]) : _vm._e()])])], 1) : _vm._e(), _vm.type == 1 ? _c('div', [_c('el-upload', {
    staticClass: "picture-uploader",
    attrs: {
      "action": "",
      "accept": "image/gif, image/jpeg, image/jpg, image/png, image/webp",
      "list-type": "picture-card",
      "multiple": "",
      "auto-upload": false,
      "file-list": _vm.fileList,
      "on-remove": _vm.handleRemove,
      "on-change": _vm.imgChangeNUpload
    },
    scopedSlots: _vm._u([{
      key: "file",
      fn: function fn(_ref2) {
        var file = _ref2.file;
        return _c('div', {}, [_c('img', {
          staticClass: "el-upload-list__item-thumbnail",
          attrs: {
            "src": file.url,
            "alt": ""
          }
        })]);
      }
    }], null, false, 3310844383)
  }, [_c('i', {
    staticClass: "el-icon-plus",
    attrs: {
      "slot": "default"
    },
    slot: "default"
  })])], 1) : _vm._e(), _vm.type == 2 ? _c('div', {
    staticClass: "picture-uploader-type2",
    class: [_vm.size]
  }, [_c('el-upload', {
    staticClass: "uploader",
    attrs: {
      "action": "",
      "accept": _vm.accept,
      "multiple": "",
      "auto-upload": false,
      "show-file-list": false,
      "file-list": _vm.fileList,
      "on-remove": _vm.handleRemove,
      "on-change": _vm.imgChangeNUpload
    }
  }, [_vm.fileList[0] && _vm.fileList[0].url ? _c('div', [_c('img', {
    staticClass: "img",
    attrs: {
      "src": _vm.fileList[0].url
    }
  }), _c('div', {
    staticClass: "text center mask"
  }, [_c('i', {
    staticClass: "el-icon-upload"
  }), _vm._v(" 重新上传 ")])]) : _c('div', {
    staticClass: "text center"
  }, [_c('i', {
    staticClass: "el-icon-upload"
  }), _vm._v(" 点击上传 ")]), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingList[0],
      expression: "loadingList[0]"
    }],
    staticClass: "loading"
  })]), _vm.title ? _c('p', {
    staticClass: "label",
    class: {
      'f-span-required': _vm.required
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _vm._e(), _vm.tips ? _c('p', {
    staticClass: "tips"
  }, [_vm._v(_vm._s(_vm.tips))]) : _vm._e()], 1) : _vm._e(), _vm.type == 4 ? _c('div', [_c('el-upload', {
    staticClass: "uploader",
    attrs: {
      "action": "",
      "accept": "image/gif, image/jpeg, image/jpg, image/png, image/webp",
      "multiple": "",
      "auto-upload": false,
      "show-file-list": false,
      "file-list": _vm.fileList,
      "on-remove": _vm.handleRemove,
      "on-change": _vm.imgChangeNUpload
    }
  }, [_c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium",
      "icon": "el-icon-document-add"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingList[0],
      expression: "loadingList[0]"
    }],
    staticClass: "loading"
  })], 1)], 1) : _vm._e(), _vm.type == 5 ? _c('div', [_c('el-upload', {
    staticClass: "upload-demo",
    attrs: {
      "drag": "",
      "action": "",
      "multiple": "",
      "accept": _vm.accept,
      "auto-upload": false,
      "file-list": _vm.fileList,
      "show-file-list": false,
      "on-change": _vm.imgChangeNUpload
    }
  }, [_c('i', {
    staticClass: "el-icon-upload"
  }), _c('div', {
    staticClass: "el-upload__text"
  }, [_vm._v("将文件拖到此处，或"), _c('em', [_vm._v("点击上传")])]), _c('div', {
    staticClass: "el-upload__tip",
    staticStyle: {
      "line-height": "150%"
    },
    attrs: {
      "slot": "tip"
    },
    slot: "tip"
  }, [_vm._v(" 文档（支持word、Excel、PDF）、图片（支持JPG、png、gif），且单个文档不超过" + _vm._s(_vm.limitSize) + "M ")])])], 1) : _vm._e(), _vm.type == 6 ? _c('div', [_c('el-upload', {
    staticClass: "upload-demo",
    attrs: {
      "action": "",
      "accept": _vm.accept,
      "auto-upload": false,
      "file-list": _vm.fileList,
      "show-file-list": false,
      "limit": _vm.limit,
      "on-change": _vm.imgChangeNUpload
    }
  }, [_c('el-button', {
    attrs: {
      "type": "primary",
      "size": "small",
      "plain": ""
    }
  }, [_vm._v(_vm._s(_vm.buttonText))])], 1)], 1) : _vm._e(), _vm.type == 7 ? _c('div', [_c('el-upload', {
    staticClass: "upload-demo",
    attrs: {
      "action": "",
      "multiple": "",
      "accept": _vm.accept,
      "auto-upload": false,
      "file-list": _vm.fileList,
      "show-file-list": false,
      "on-change": _vm.imgChangeNUpload
    }
  }, [_c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium",
      "icon": "el-icon-document-add",
      "loading": _vm.loadingList.some(function (item) {
        return item;
      })
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])], 1)], 1) : _vm._e(), _vm.type == 8 ? _c('div', {
    staticClass: "uploader-type8",
    class: [_vm.size]
  }, [_c('el-upload', {
    attrs: {
      "action": "",
      "accept": _vm.accept,
      "multiple": "",
      "auto-upload": false,
      "show-file-list": false,
      "file-list": _vm.fileList,
      "on-remove": _vm.handleRemove,
      "on-change": _vm.imgChangeNUpload
    }
  }, [_vm.fileList[0] && _vm.fileList[0].url ? _c('div', {
    staticClass: "file"
  }, [_c('div', {
    staticClass: "type-icon",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm.fileList[0].docType == 'word' ? _c('img', {
    attrs: {
      "src": require("@/assets/word.png")
    }
  }) : _vm._e(), _vm.fileList[0].docType == 'excel' ? _c('img', {
    attrs: {
      "src": require("@/assets/excel.png")
    }
  }) : _vm._e(), _vm.fileList[0].docType == 'pdf' ? _c('img', {
    attrs: {
      "src": require("@/assets/pdf.png")
    }
  }) : _vm._e(), _vm.fileList[0].docType == 'image' ? _c('img', {
    attrs: {
      "src": _vm.item.url
    }
  }) : _vm._e()]), _c('p', {
    staticClass: "text",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('span', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.fileList[0].name))]), _c('span', {
    staticClass: "size"
  }, [_vm._v(_vm._s(_vm.$format.byte(_vm.fileList[0].size)))])]), _c('p', {
    staticClass: "actions"
  }, [_vm.loadingList[0] ? _c('span', {
    staticClass: "el-icon-loading"
  }) : [_c('span', [_vm._v("[重新上传]")]), _c('span', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.handleRemove(_vm.fileList[0]);
      }
    }
  }, [_vm._v("[删除]")])]], 2)]) : _c('div', {
    staticClass: "uploader"
  }, [_c('div', {
    staticClass: "text center"
  }, [_c('i', {
    staticClass: "el-icon-upload"
  }), _vm._v(" 选择文档 ")]), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingList[0],
      expression: "loadingList[0]"
    }],
    staticClass: "loading"
  })])])], 1) : _vm._e(), _c('el-dialog', {
    attrs: {
      "title": "图片剪裁",
      "visible": _vm.toggleCropperDialog,
      "close-on-click-modal": false,
      "append-to-body": "",
      "width": "540px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.toggleCropperDialog = $event;
      }
    }
  }, [_c('div', {
    staticClass: "upload-cropper"
  }, [_c('vue-cropper', {
    ref: "cropper",
    attrs: {
      "img": _vm.cropperOption.img,
      "outputType": _vm.cropperOption.outputType,
      "autoCrop": _vm.cropperOption.autoCrop,
      "fixed": _vm.cropperOption.fixed,
      "fixedNumber": _vm.cropperOption.fixedNumber,
      "infoTrue": _vm.cropperOption.infoTrue,
      "centerBox": _vm.cropperOption.centerBox,
      "enlarge": _vm.cropperOption.enlarge
    }
  })], 1), _c('div', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.toggleCropperDialog = false;
      }
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.cropFinish
    }
  }, [_vm._v("确认")])], 1)]), _c('el-dialog', {
    attrs: {
      "title": "大图预览",
      "visible": _vm.previewImageVisible,
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.previewImageVisible = $event;
      }
    }
  }, [_c('img', {
    attrs: {
      "width": "100%",
      "src": _vm.previewImageUrl,
      "alt": ""
    }
  })])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }